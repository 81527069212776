import M from 'materialize-css';

import '../styles/materialize';
import '../styles/navigation';
import '../styles/header';
import '../styles/main';

import '../scripts/csrf_form_injector';
import '../scripts/auto_fill';

document.addEventListener('DOMContentLoaded', function() {
  M.AutoInit();
});
